import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Stack } from "@mui/material";

import { useTrackPageView } from "~common/tracking";
import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import { scrollToTop } from "~common/utils/scrollToTop";
import CatchCardApplication from "~src/components/shared/onboarding/CatchCardApplication";
import CatchCardApplicationSuccess from "~src/components/shared/onboarding/CatchCardApplicationSuccess";
import Education from "~src/components/shared/onboarding/Education";
import LinkDebit from "~src/components/shared/onboarding/LinkDebit";
import PinForm from "~src/components/shared/onboarding/PinForm";
import PlaidIDVLink from "~src/components/shared/onboarding/PlaidIDVLink";
import { selectCatchCard } from "~src/store";
import { setAuthUserInfo, setCatchCard } from "~src/store/slices/user-slice";

import ApplePay from "./ApplePay";
import ClaimReward from "./ClaimReward";

const ActivateCard: React.VFC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeScreen, setActiveScreen] = useState("education");
  const [initialScreen, setInitialScreen] = useState("debit");
  const [isEditing, setIsEditing] = useState(false);
  const catchCard = useSelector(selectCatchCard);

  useTrackPageView("Viewed Catch Card Edu Page");

  useEffect(() => {
    if (catchCard?.user_flow_status === "approved" && !catchCard?.is_pin_set) {
      setInitialScreen("pin");
    }
  }, [catchCard]);

  useEffect(() => {
    scrollToTop("instant");
  }, [activeScreen]);

  const handleFinish = () => {
    removeSessionStorageWithFallback("activateCardRequired");
    dispatch(setAuthUserInfo({ activateCardRequired: false }));

    /*
     * Wait until the user exits card activation to update the
     * PIN status in the Redux store
     */
    dispatch(
      setCatchCard({
        ...catchCard,
        is_pin_set: true,
      })
    );

    navigate("/home");
  };

  return (
    <Stack
      sx={{
        minHeight: "100%",
        "& > .MuiBox-root": {
          flexGrow: 1,
          pb: 12,
          pt: {
            md: 18,
          },
        },
      }}
    >
      {activeScreen === "education" && (
        <Education
          onClick={() => setActiveScreen(initialScreen)}
          initialScreen={initialScreen}
        />
      )}

      {activeScreen === "debit" && (
        <LinkDebit
          onEdit={setIsEditing}
          isEditing={isEditing}
          onSuccess={() => setActiveScreen("idv")}
        />
      )}

      {activeScreen === "idv" && (
        <PlaidIDVLink
          onSuccess={() => setActiveScreen("confirmation")}
          onRetryDebit={() => setActiveScreen("debit")}
          showContinue
        />
      )}

      {activeScreen === "confirmation" && (
        <CatchCardApplication
          onSuccess={() => setActiveScreen("success")}
          showContinue
        />
      )}

      {activeScreen === "success" && (
        <CatchCardApplicationSuccess onClick={() => setActiveScreen("pin")} />
      )}

      {activeScreen === "pin" && (
        <PinForm onSuccess={() => setActiveScreen("applePay")} />
      )}

      {activeScreen === "applePay" && (
        <ApplePay onSuccess={() => setActiveScreen("reward")} />
      )}

      {activeScreen === "reward" && <ClaimReward onSuccess={handleFinish} />}
    </Stack>
  );
};

export default ActivateCard;
