import React, { useEffect, useRef, useState } from "react";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "lodash-es/debounce";
import SmartySDK from "smartystreets-javascript-sdk";

import { HouseIcon } from "~common/components/icons/vector";
import { UserBillingAddress } from "~common/services";
import { useTracking } from "~common/tracking";
import { SMARTY_STREETS_KEY } from "~src/config";

type AddressAutocompleteProps = {
  onSelect: (address: UserBillingAddress) => void;
};

const AddressAutocomplete: React.VFC<AddressAutocompleteProps> = ({
  onSelect,
}) => {
  const { trackEvent, trackError } = useTracking();
  const client =
    useRef<SmartySDK.core.Client<
      SmartySDK.usAutocompletePro.Lookup,
      SmartySDK.usAutocompletePro.Lookup
    > | null>(null);
  const [query, setQuery] = useState("");
  const [results, setResults] =
    useState<SmartySDK.usAutocompletePro.Suggestion[] | null>(null);

  useEffect(() => {
    const { core } = SmartySDK;
    const credentials = new core.SharedCredentials(SMARTY_STREETS_KEY);
    const clientBuilder = new core.ClientBuilder(credentials).withLicenses([
      "us-autocomplete-pro-cloud",
    ]);

    client.current = clientBuilder.buildUsAutocompleteProClient();
  }, []);

  useEffect(() => {
    const handleChange = debounce(async () => {
      if (!client.current || !query) {
        return;
      }

      const { usAutocompletePro } = SmartySDK;
      const lookup = new usAutocompletePro.Lookup(query);

      try {
        const response = await client.current.send({
          ...lookup,
          maxResults: 3,
        });

        setResults(response.result);
      } catch (err) {
        trackError("AddressAutocomplete", "Smarty Address Lookup", {
          error: err,
        });
      }
    }, 1000);

    void handleChange();
  }, [query, trackError]);

  const handleSelect = (address: SmartySDK.usAutocompletePro.Suggestion) => {
    trackEvent("Autocomplete Address Selected");

    onSelect({
      address_1: address.streetLine,
      address_2: address.secondary,
      city: address.city,
      zone_code: address.state,
      postal_code: address.zipcode,
      country_code: "US",
    });
  };

  return (
    <Stack spacing={4.5}>
      <TextField
        label="Billing address"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        autoFocus
      />

      {!!results?.length && (
        <List disablePadding>
          {results?.map((result) => (
            <ListItem key={JSON.stringify(result)} disablePadding>
              <ListItemButton
                disableGutters
                disableRipple
                onClick={() => handleSelect(result)}
                sx={{ p: 0 }}
              >
                <ListItemAvatar sx={{ minWidth: 44 }}>
                  <Avatar
                    sx={({ palette }) => ({
                      background: palette.grey[300],
                      width: 32,
                      height: 32,
                      color: palette.grey[500],
                    })}
                  >
                    <HouseIcon size="original" />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      component="div"
                      variant="bodyRegular"
                      color="grey.700"
                    >
                      {result.streetLine}
                      {result.secondary ? `, ${result.secondary}` : ""}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="div"
                      color="grey.700"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {result.city}, {result.state} {result.zipcode}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

export default AddressAutocomplete;
