import React from "react";

import { Typography } from "@mui/material";

import ExternalTextLink from "../links/ExternalTextLink";

// TODO: This component is currently duplicated within
//       checkout-v2/src/components/steps/answer-otp/AnswerOTP.tsx
//       and should be used in Checkout as well to share common code.

const VerifyPhoneDisclaimer: React.VFC = () => (
  <Typography
    variant="bodySmall"
    sx={{
      a: {
        typography: "linkSmall",
      },
    }}
  >
    By verifying my phone number, I agree to the Catch{" "}
    <ExternalTextLink
      size="small"
      href="https://www.getcatch.com/terms"
      eventName="Terms of Service Clicked"
      componentName="VerifyPhoneDisclaimer"
    >
      Terms of Service
    </ExternalTextLink>{" "}
    and{" "}
    <ExternalTextLink
      size="small"
      href="https://www.getcatch.com/privacy"
      eventName="Privacy Policy Clicked"
      componentName="VerifyPhoneDisclaimer"
    >
      Privacy Policy
    </ExternalTextLink>
    .
  </Typography>
);

export default VerifyPhoneDisclaimer;
