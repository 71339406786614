import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import useInitiatePlaidIDVLink from "~src/hooks/useInitiatePlaidIDVLink";
import { ApplicationError } from "~src/utils/catch-card";

import CatchCardError from "./CatchCardError";

type PlaidIDVLinkProps = {
  onSuccess: () => void;
  onRetryDebit: () => void;
  showContinue?: boolean;
};

const PlaidIDVLink: React.VFC<PlaidIDVLinkProps> = ({
  onSuccess,
  onRetryDebit,
  showContinue,
}) => {
  const { trackEvent } = useTracking();
  const {
    ready,
    open,
    verifying,
    verified,
    pending,
    denied,
    debitAuthFailed,
    error,
  } = useInitiatePlaidIDVLink();
  const [errorType, setErrorType] = useState<ApplicationError | null>(null);

  useEffect(() => {
    if (pending) {
      setErrorType("pending");
    }

    if (debitAuthFailed) {
      setErrorType("debitAuthFailed");
    }

    if (denied) {
      setErrorType("denied");
    }

    if (error) {
      setErrorType("error");
    }
  }, [pending, debitAuthFailed, denied, error]);

  const handleOpen = () => {
    trackEvent("Plaid IDV Link Opened");
    open();
  };

  const handleContinue = () => {
    onSuccess();
  };

  if (errorType) {
    return (
      <SmallPagePanel bgcolor="tertiary.main">
        <Box mt={-4}>
          <CatchCardError
            errorType={errorType}
            showIcon
            mode="dark"
            showContinue={errorType === "pending" ? showContinue : undefined}
            onRetryDebit={
              errorType === "debitAuthFailed" ? onRetryDebit : undefined
            }
          />
        </Box>
      </SmallPagePanel>
    );
  }

  if (verified) {
    return (
      <SmallPagePanel
        title="It IS you!"
        subtitle="We've successfully validated your identity. Now it's finally time to reveal your brand new Catch card!"
        bgcolor="tertiary.main"
      >
        <Box
          pt={{
            zero: 3,
            md: 6,
          }}
        >
          {ready && (
            <PrimaryButton onClick={handleContinue} fullWidth>
              Get your Catch card!
            </PrimaryButton>
          )}
        </Box>
      </SmallPagePanel>
    );
  }

  return (
    <SmallPagePanel
      title={errorType ? undefined : "Do we have the right you?"}
      subtitle={
        errorType ? undefined : (
          <>
            Just need to confirm your identity and the card is yours! We use
            Plaid to run a quick, secure identity check (a legal requirement).
            Get your SSN—<strong>the last 4 digits</strong>—ready!
          </>
        )
      }
      bgcolor="tertiary.main"
    >
      <Box
        pt={{
          zero: 3,
          md: 6,
        }}
      >
        {ready && (
          <PrimaryButton onClick={handleOpen} loading={verifying} fullWidth>
            Continue
          </PrimaryButton>
        )}
      </Box>
    </SmallPagePanel>
  );
};

export default PlaidIDVLink;
