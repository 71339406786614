import React from "react";

import { BoxProps, Stack, Typography } from "@mui/material";

type SmallPagePanelProps = Omit<BoxProps, "title"> & {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
  centered?: boolean;
};

const SmallPagePanel: React.FC<SmallPagePanelProps> = ({
  icon,
  title,
  subtitle,
  centered,
  footer,
  children,
  bgcolor = "common.white",
  sx = [],
}) => {
  const darkMode =
    bgcolor === "tertiary.main" || bgcolor === "tints.purple.dark";

  return (
    <Stack
      bgcolor={bgcolor}
      sx={[
        ({ palette }) => ({
          minHeight: "100%",
          px: 6,
          py: 8,
          flexGrow: 1,
          ...(!icon && {
            pt: 12,
          }),
          ...(darkMode && {
            color: palette.common.white,
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        spacing={16}
        sx={{
          maxWidth: darkMode
            ? "var(--xsmall-page-panel-max-width)"
            : "var(--small-page-panel-max-width)",
          minHeight: "100%",
          margin: "0 auto",
          textAlign: centered ? "center" : "left",
        }}
      >
        <Stack
          spacing={6}
          flexGrow={1}
          alignItems={centered ? "center" : undefined}
        >
          {(icon || title || subtitle) && (
            <Stack spacing={4} alignItems={centered ? "center" : undefined}>
              {icon && icon}

              {title && (
                <Typography
                  variant="h1"
                  fontWeight={900}
                  letterSpacing="-0.02em"
                  sx={{
                    ...(darkMode && {
                      fontSize: {
                        md: 32,
                      },
                    }),
                  }}
                >
                  {title}
                </Typography>
              )}

              {subtitle && (
                <Typography
                  variant="bodyRegular"
                  paragraph
                  sx={
                    darkMode
                      ? ({ palette }) => ({
                          strong: {
                            color: palette.tertiary.contrastText,
                          },
                        })
                      : undefined
                  }
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>
          )}

          {children}
        </Stack>

        {footer}
      </Stack>
    </Stack>
  );
};

SmallPagePanel.defaultProps = {
  icon: undefined,
  title: undefined,
  subtitle: undefined,
  footer: undefined,
  centered: false,
};

export default SmallPagePanel;
