import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Check as CheckIcon } from "@mui/icons-material";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { PrimaryButton } from "~common/components/controls/buttons";
import { PinInput } from "~common/components/controls/text-fields";
import { useUpdateAnywhereCardPin } from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectCatchCard } from "~src/store";

type PinFormProps = {
  onSuccess: () => void;
};

const PinForm: React.VFC<PinFormProps> = ({ onSuccess }) => {
  const { trackEvent, trackError } = useTracking();
  const catchCard = useSelector(selectCatchCard);
  const { mutate: updateAnywhereCardPin } = useUpdateAnywhereCardPin();
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState(catchCard?.is_pin_set || false);

  const handleChange = (value: string) => {
    setPin(value);
    setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (pin.length !== 4) {
      setErrors({
        pin: "Please enter a four digit PIN",
      });

      return;
    }

    if (!catchCard?.card_id) {
      trackError("PinForm", "PIN", { error: "Missing card_id" });

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });

      return;
    }

    trackEvent("Catch Card PIN Submitted");
    setLoading(true);

    try {
      await updateAnywhereCardPin(
        { pin },
        {
          pathParams: { issuedCardId: catchCard?.card_id },
        }
      );

      trackEvent("Catch Card PIN Saved");
      setLoading(false);
      setSuccess(true);
      onSuccess();
    } catch (err) {
      trackError("PinForm", "PIN", { error: err });
      setLoading(false);

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });
    }
  };

  return (
    <SmallPagePanel
      title="Create your card PIN."
      subtitle="Stores might ask for a 4-digit PIN when you use your Catch card with Apple Pay."
      bgcolor="tertiary.main"
    >
      <Stack spacing={10} component="form" onSubmit={handleSubmit}>
        <PinInput
          numInputs={4}
          value={pin}
          updateValue={handleChange}
          error={!!errors.pin}
          errorMessage={errors.pin}
          disabled={loading || success}
          mode="dark"
        />

        {errors.generic && (
          <FormHelperText error>{errors.generic}</FormHelperText>
        )}

        <Stack
          spacing={1}
          px={9}
          py={6}
          color="common.white"
          sx={({ shape }) => ({
            background: "#531CB0",
            borderRadius: `${shape.borderRadius * 2}px`,
          })}
        >
          <Typography variant="h3">Don&apos;t forget it.</Typography>

          <Typography variant="bodySmall">
            Make sure to memorize your PIN or write it down, or both!
          </Typography>
        </Stack>

        <PrimaryButton
          type="submit"
          startIcon={success ? <CheckIcon /> : undefined}
          loading={loading}
          sx={{
            pointerEvents: success || loading ? "none" : "auto",
          }}
        >
          {success ? (
            "Your PIN is set"
          ) : (
            <>
              Continue
              <Box sx={visuallyHidden}> to finish your activation</Box>
            </>
          )}
        </PrimaryButton>
      </Stack>
    </SmallPagePanel>
  );
};

export default PinForm;
