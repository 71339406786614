import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, FormHelperText, Link, Stack, Typography } from "@mui/material";

import catchCardActivated from "~common/assets/illustrations/catch_card_activated.png";
import { PrimaryButton } from "~common/components/controls/buttons";
import { useTransactionalSms } from "~common/services/sms";
import { useTracking } from "~common/tracking";
import { ADOPTION_URLS } from "~common/utils/adoption";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectCurrentUser } from "~src/store";
import { getDevice } from "~src/utils/devices";

type ApplePayProps = {
  onSuccess: () => void;
};

const ApplePay: React.VFC<ApplePayProps> = ({ onSuccess }) => {
  const { trackEvent, trackError } = useTracking();
  const device = getDevice();
  const { mutate: transactionalSms } = useTransactionalSms();
  const currentUser = useSelector(selectCurrentUser.data);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onSuccess();
      }, 5000);
    }
  }, [success, onSuccess]);

  useEffect(() => {
    if (device === "android") {
      onSuccess();
    }
  }, [device, onSuccess]);

  const handleClick = async () => {
    trackEvent("Catch Card Add to Apple Wallet Clicked");

    if (device === "iphone") {
      window.location.href = ADOPTION_URLS.ios;
      return;
    }

    if (loading || !currentUser?.phone_number) {
      return;
    }

    setError("");
    setLoading(true);

    try {
      await transactionalSms({
        phone_number: currentUser?.phone_number,
        message_type: "ios_app_link",
        data: {
          singular_link: ADOPTION_URLS.ios,
        },
      });

      setLoading(false);
      setSuccess(true);
      trackEvent("Catch Card Apple Wallet SMS Sent");
    } catch (err) {
      setLoading(false);
      setError("Something didn't work as expected. Please try again.");
      trackError("ApplePay", "Send SMS ", { error: err });
    }
  };

  const handleLater = () => {
    trackEvent("Catch Card Do this later Clicked");
    onSuccess();
  };

  if (device === "android") {
    return null;
  }

  return (
    <SmallPagePanel
      title="Last step! Add your Catch card to Apple Pay."
      subtitle={
        <>
          Make it easy for yourself. Add your shiny new Catch card to Apple
          wallet, <strong>make it your default card</strong>, and start earning
          money back everywhere you shop!
        </>
      }
      bgcolor="tertiary.main"
    >
      <Stack
        spacing={{
          zero: 10,
          md: 9,
        }}
        pt={{
          zero: 3,
          md: 6,
        }}
        alignItems="center"
      >
        <Box
          component="img"
          src={catchCardActivated}
          width={{
            zero: 180,
            md: 220,
          }}
          height={{
            zero: 212,
            md: 260,
          }}
          alt="Catch card activated!"
        />

        {error && (
          <FormHelperText error>
            <Typography variant="inherit" color="tints.pink.dark">
              {error}
            </Typography>
          </FormHelperText>
        )}

        <PrimaryButton
          onClick={handleClick}
          fullWidth
          loading={loading}
          sx={{
            pointerEvents: success || loading ? "none" : "auto",
          }}
        >
          {success ? "Text message sent!" : "Text me a link"}
        </PrimaryButton>

        <Link component="button" onClick={handleLater}>
          <Typography variant="linkSmall">Do this later</Typography>
        </Link>
      </Stack>
    </SmallPagePanel>
  );
};

export default ApplePay;
