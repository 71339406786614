import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Box, Stack, Typography } from "@mui/material";

import usePreviousValue from "../../../hooks/usePreviousValue";

const makeRangeList = (length: number): Array<number> => {
  const out = [];
  for (let i = 0; i < length; i += 1) {
    out.push(i);
  }
  return out;
};

const makeClassNames = (...classNames: unknown[]): string =>
  classNames
    .filter((className) => typeof className !== "object" && className !== false)
    .join(" ");

const isNumericString = (value: string): boolean => !isNaN(parseInt(value, 10));

type DigitFieldProps = {
  index: number;
  isFirst: boolean;
  value: string | undefined;
  isFocused: boolean;
  isErrored: boolean;
  isDisabled: boolean;
  isUntabbable: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  mode?: "dark" | "light";
};

const DigitField: React.VFC<DigitFieldProps> = ({
  index,
  isFirst,
  value,
  isFocused,
  isErrored,
  isDisabled,
  isUntabbable,
  onChange,
  onInput,
  onKeyDown,
  onPaste,
  onFocus,
  onBlur,
  mode,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const wasFocused = usePreviousValue(isFocused);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      if (isFocused && !wasFocused) {
        input.focus();
        input.select();
      } else if (wasFocused && !isFocused) {
        input.blur();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <Box
      sx={[
        ({ spacing, shape, palette }) => ({
          display: "flex",
          alignItems: "center",

          input: {
            border: "1px solid",
            borderRadius: `${shape.textFieldBorderRadius}px`,
            width: spacing(10),
            height: spacing(13),
            fontFamily: "inherit",
            typography: "bodyLarge",
            textAlign: "center",
            ...(mode === "dark"
              ? {
                  bgcolor: "transparent",
                  color: palette.common.white,
                  borderColor: palette.tints?.purple?.main,
                  "&:not(.is-errored):not(.is-disabled):hover": {
                    borderColor: palette.tertiary.contrastText,
                  },
                  "&.is-focused": {
                    borderColor: palette.tertiary.contrastText,
                    outline: "1px solid",
                    outlineColor: palette.tertiary.contrastText,
                  },
                  "&.is-errored, &.is-errored.is-focused": {
                    borderColor: palette.tints?.pink?.dark,
                    outlineColor: palette.tints?.pink?.dark,
                  },
                  "&.is-disabled": {
                    opacity: 0.65,
                    borderColor: palette.tints?.purple?.main,
                    color: palette.tints?.purple?.main,
                  },
                }
              : {
                  bgcolor: "background.default",
                  borderColor: "grey.500",
                  "&:not(.is-errored):not(.is-disabled):hover": {
                    borderColor: "grey.700",
                  },
                  "&.is-focused": {
                    borderColor: "grey.700",
                    outline: "1px solid",
                    outlineColor: palette.grey[700],
                  },
                  "&.is-errored, &.is-errored.is-focused": {
                    borderColor: "error.main",
                    outlineColor: palette.error.main,
                  },
                  "&.is-disabled": {
                    borderColor: "grey.300",
                    color: "grey.500",
                  },
                }),
          },
        }),
      ]}
    >
      <input
        ref={inputRef}
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        maxLength={!isFirst ? 1 : undefined}
        aria-label={`${
          isFirst ? "Please enter verification code. " : ""
        }Digit ${index + 1}`}
        className={makeClassNames(
          isFocused && "is-focused",
          isErrored && "is-errored",
          isDisabled && "is-disabled"
        )}
        value={value || ""}
        disabled={isDisabled}
        onChange={onChange}
        onInput={onInput}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={isUntabbable ? -1 : undefined}
      />
    </Box>
  );
};

type PinInputProps = {
  value: string;
  updateValue: (value: string) => void;
  numInputs?: number;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  mode?: "dark" | "light";
};

const PinInput: React.VFC<PinInputProps> = ({
  value,
  updateValue,
  numInputs = 6,
  error,
  errorMessage,
  disabled,
  mode,
}) => {
  const valueList = useMemo(() => value.trim().split(""), [value]);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const activateField = useCallback(
    (index: number) => {
      if (index < 0) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(Math.min(numInputs - 1, index));
      }
    },
    [numInputs]
  );
  const activateNextField = useCallback(
    () => activateField(activeIndex + 1),
    [activateField, activeIndex]
  );
  const activatePreviousField = useCallback(
    () => activateField(Math.max(activeIndex - 1, 0)),
    [activateField, activeIndex]
  );

  const updateActiveFieldValue = useCallback(
    (fieldValue: string) => {
      const buf = valueList.slice(0, activeIndex);
      // eslint-disable-next-line prefer-destructuring
      buf[activeIndex] = fieldValue[0];
      updateValue(buf.join(""));
    },
    [valueList, activeIndex, updateValue]
  );

  const handleFieldOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue = e.target.value;
      if (isNumericString(fieldValue)) {
        if (fieldValue.length > 1) {
          updateValue(fieldValue);
        } else {
          updateActiveFieldValue(fieldValue);
        }
      }
    },
    [updateActiveFieldValue, updateValue]
  );

  const handleFieldOnInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldVal = e.target.value;
      if (fieldVal.length === 1 && isNumericString(fieldVal)) {
        activateNextField();
      }
    },
    [activateNextField]
  );

  const handleFieldOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { code } = e;

      if (code === "Backspace") {
        e.preventDefault();
        updateActiveFieldValue("");
        activatePreviousField();
      } else if (code === "Delete") {
        e.preventDefault();
        updateActiveFieldValue("");
      } else if (code === "ArrowLeft") {
        e.preventDefault();
        activatePreviousField();
      } else if (code === "ArrowRight") {
        e.preventDefault();
        activateNextField();
      } else if (code === "Space") {
        e.preventDefault();
      }
    },
    [updateActiveFieldValue, activatePreviousField, activateNextField]
  );

  const handleFieldOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (disabled) {
        return;
      }

      let targetIndex = activeIndex;
      const pasteList = e.clipboardData
        .getData("text/plain")
        .split("")
        .filter((c) => isNumericString(c));
      const buf = valueList.slice();

      for (let pos = 0; pos < numInputs; pos += 1) {
        if (pos >= activeIndex && pasteList.length > 0) {
          buf[pos] = pasteList.shift() || "";
          targetIndex += 1;
        }
      }

      activateField(targetIndex);
      updateValue(buf.join(""));
    },
    [activateField, activeIndex, disabled, numInputs, updateValue, valueList]
  );

  useEffect(() => {
    if (disabled) {
      activateField(-1);
    }
  }, [activateField, disabled]);

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        {makeRangeList(numInputs).map((index) => (
          <DigitField
            key={index}
            index={index}
            isFirst={index === 0}
            value={valueList && valueList[index]}
            isFocused={activeIndex === index}
            isErrored={!!error}
            isDisabled={!!disabled}
            isUntabbable={index > valueList.length}
            onChange={handleFieldOnChange}
            onInput={handleFieldOnInput}
            onKeyDown={handleFieldOnKeyDown}
            onPaste={handleFieldOnPaste}
            onFocus={() => activateField(Math.min(index, valueList.length))}
            onBlur={() => activateField(-1)}
            mode={mode}
          />
        ))}
      </Stack>
      {error && (
        <Typography
          paragraph
          variant="bodySmall"
          sx={({ palette }) => ({
            color: mode === "dark" ? palette.tints?.pink?.dark : "error.main",
            mt: 0.75,
            mb: 0,
          })}
        >
          {errorMessage || "Oops! That code doesn't match"}
        </Typography>
      )}
    </Box>
  );
};

PinInput.defaultProps = {
  error: false,
  disabled: false,
};

export default PinInput;
