import React from "react";

import { Typography } from "@mui/material";

const HoldAuthorization: React.VFC = () => (
  <Typography variant="bodySmall" color="tints.purple.main" mt={5.5}>
    By continuing, you agree to have Catch place a temporary hold on your card
    for verification purposes. The hold will be released within minutes.
  </Typography>
);

export default HoldAuthorization;
