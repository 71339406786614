import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import FullHeightDialog from "~common/components/modals/FullHeightDialog";
import type { UserBillingAddress } from "~common/services";
import { useTrackPageView } from "~common/tracking";
import { setSessionStorageWithFallback } from "~common/utils/browser-storage";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import AddressAutocomplete from "~src/components/shared/onboarding/AddressAutocomplete";
import CurrentUserForm from "~src/components/shared/onboarding/CurrentUserForm";
import { selectCatchCard, selectCurrentUser } from "~src/store";
import {
  markEmailVerified,
  setAuthUserInfo,
} from "~src/store/slices/user-slice";

const CompleteAccount: React.VFC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const [address, setAddress] = useState(currentUser?.billing_address || null);
  const [editAddress, setEditAddress] = useState(false);

  useTrackPageView("Complete Account");

  useEffect(() => {
    if (currentUser) {
      setAddress(currentUser.billing_address || null);
    }
  }, [currentUser]);

  const handleSelectAddress = (value: UserBillingAddress) => {
    setAddress(value);
    setEditAddress(false);
  };

  const handleSuccess = () => {
    if (catchCard?.user_flow_status !== "approved" || !catchCard?.is_pin_set) {
      setSessionStorageWithFallback("activateCardRequired", "true");
      dispatch(setAuthUserInfo({ activateCardRequired: true }));
    }

    dispatch(markEmailVerified());
  };

  return (
    <>
      <SmallPagePanel
        title="Let's create your profile."
        subtitle={
          <>
            Be sure to enter your full legal name and birthday{" "}
            <strong>as they appear on your ID</strong>. This will speed up the
            ID verification process.
          </>
        }
      >
        <Box
          pt={{
            zero: 2.5,
            md: 5.5,
          }}
        >
          <CurrentUserForm
            isNewUser
            address={address}
            onEditAddress={() => setEditAddress(true)}
            onSuccess={handleSuccess}
          />
        </Box>
      </SmallPagePanel>

      <FullHeightDialog
        heading="Billing Address"
        open={editAddress}
        onClose={() => setEditAddress(false)}
      >
        <AddressAutocomplete onSelect={handleSelectAddress} />
      </FullHeightDialog>
    </>
  );
};

export default CompleteAccount;
