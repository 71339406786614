import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FullHeightDialog, {
  FullHeightDialogProps,
} from "~common/components/modals/FullHeightDialog";
import { selectCatchCard } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";

import CatchCardApplication from "../shared/onboarding/CatchCardApplication";
import CatchCardApplicationSuccess from "../shared/onboarding/CatchCardApplicationSuccess";
import Education from "../shared/onboarding/Education";
import LinkDebit from "../shared/onboarding/LinkDebit";
import PinForm from "../shared/onboarding/PinForm";
import PlaidIDVLink from "../shared/onboarding/PlaidIDVLink";
import CatchCardContactInfo from "./CatchCardContactInfo";
import CatchCardLanding from "./CatchCardLanding";

type CatchCardModalProps = FullHeightDialogProps & {
  initialScreen?: "landing" | "contact" | "debit" | "confirmation" | "pin";
};

const CatchCardModal: React.VFC<CatchCardModalProps> = ({
  initialScreen,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const catchCard = useSelector(selectCatchCard);
  const [activeScreen, setActiveScreen] = useState<keyof typeof screens>(
    initialScreen || "landing"
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showEditingCancel, setShowEditingCancel] = useState(false);
  const [hasApplicationError, setHasApplicationError] = useState(false);

  const handleEdit = useCallback((isActive: boolean, hideCancel?: boolean) => {
    setIsEditing(isActive);
    setShowEditingCancel(isActive && !hideCancel);
  }, []);

  const handlePinSuccess = () => {
    dispatch(
      setCatchCard({
        ...catchCard,
        is_pin_set: true,
      })
    );
  };

  const screens = {
    landing: {
      heading: "Activate your Catch Card",
      component: <CatchCardLanding onNext={() => setActiveScreen("contact")} />,
    },
    contact: {
      heading: isEditing ? "Billing Address" : "Contact Info",
      component: (
        <CatchCardContactInfo
          onNext={() => setActiveScreen("education")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
    },
    education: {
      heading: "",
      component: <Education onClick={() => setActiveScreen("debit")} />,
    },
    debit: {
      heading: "Catch card activation",
      component: (
        <LinkDebit
          onSuccess={() => setActiveScreen("idv")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
    },
    idv: {
      heading: "Catch card activation",
      component: (
        <PlaidIDVLink
          onSuccess={() => setActiveScreen("confirmation")}
          onRetryDebit={() => setActiveScreen("debit")}
        />
      ),
    },
    confirmation: {
      heading: undefined,
      component: (
        <CatchCardApplication
          onSuccess={() => setActiveScreen("success")}
          onError={() => setHasApplicationError(true)}
        />
      ),
    },
    success: {
      heading: undefined,
      component: (
        <CatchCardApplicationSuccess onClick={() => setActiveScreen("pin")} />
      ),
    },
    pin: {
      heading: "Catch card activation",
      component: <PinForm onSuccess={handlePinSuccess} />,
    },
  };

  useEffect(() => {
    if (initialScreen) {
      setActiveScreen(initialScreen);
    }
  }, [initialScreen]);

  return (
    <FullHeightDialog
      heading={screens[activeScreen]?.heading}
      keepMounted
      onClose={
        (activeScreen === "confirmation" && !hasApplicationError) ||
        activeScreen === "success"
          ? undefined
          : onClose
      }
      onBack={showEditingCancel ? () => handleEdit(false) : undefined}
      sx={({ palette }) => ({
        ...(activeScreen !== "landing" &&
          activeScreen !== "contact" && {
            ".MuiPaper-root": {
              background:
                activeScreen === "success"
                  ? palette.tints?.purple?.dark
                  : palette.tertiary.main,
            },
            ...((activeScreen !== "confirmation" || hasApplicationError) &&
              activeScreen !== "success" && {
                ".MuiPaper-root > .MuiBox-root:first-of-type": {
                  borderColor: "#531CB0",
                },
              }),
            "h2, button[aria-label='Close dialog'], button[aria-label='Back']":
              {
                color: palette.common.white,
              },
            ".MuiPaper-root > .MuiBox-root:last-of-type": {
              p: 0,
              px: 2,
            },
          }),
      })}
      {...props}
    >
      {screens[activeScreen]?.component}
    </FullHeightDialog>
  );
};

export default CatchCardModal;
