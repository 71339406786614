import React from "react";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";

type EditCardProps = {
  children: React.ReactNode;
  onClick?: () => void;
  mode?: "dark" | "light";
};

const EditCard: React.VFC<EditCardProps> = ({ children, onClick, mode }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    spacing={4}
    py={3}
    px={4}
    position="relative"
    sx={({ palette, shape }) => ({
      border: `1px solid ${
        mode === "dark"
          ? palette.tints?.purple?.main || ""
          : "rgba(0, 0, 0, 0.23)"
      }`,
      borderRadius: `${shape.borderRadius}px`,
    })}
  >
    <Typography
      component="div"
      color={
        mode === "dark"
          ? "common.white"
          : `${onClick ? "grey.700" : "grey.400"}`
      }
    >
      {children}
    </Typography>

    {onClick && (
      <DefaultButton
        onClick={onClick}
        size="small"
        sx={({ palette }) => ({
          ...(mode === "dark" && {
            background: palette.common.white,
            color: palette.grey[700],
          }),
        })}
      >
        Edit
      </DefaultButton>
    )}
  </Stack>
);

export default EditCard;
