import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography, useTheme } from "@mui/material";

import CatchCard from "~common/components/cards/CatchCard";
import CatchCardPlaceholders from "~common/components/cards/CatchCardPlaceholders";
import FlippableCard from "~common/components/cards/FlippableCard";
import { useLocalDeviceToken } from "~common/hooks/device-token-hooks";
import {
  useAnywhereCardApplications,
  useGetAnywhereCardForUser,
} from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectCatchCard, selectCurrentUser } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";
import {
  APPLICATION_PROCESSING_MESSAGES,
  ApplicationError,
  getApplicationData,
} from "~src/utils/catch-card";

import RotatingText from "../RotatingText";
import CatchCardError from "./CatchCardError";

type CatchCardApplicationProps = {
  onSuccess: () => void;
  onError?: () => void;
  showContinue?: boolean;
};

const CatchCardApplication: React.VFC<CatchCardApplicationProps> = ({
  onSuccess,
  onError,
  showContinue,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { trackEvent, trackError } = useTracking();
  const [deviceToken] = useLocalDeviceToken();
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const { mutate: anywhereCardApplications } = useAnywhereCardApplications();
  const { refetch: refetchCatchCard } = useGetAnywhereCardForUser({
    lazy: true,
  });
  const { paymentInstruments } = usePaymentInstruments();
  const paymentInstrument = paymentInstruments[0] || null;
  const [isPolling, setIsPolling] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorType, setErrorType] = useState<ApplicationError | null>(null);

  useEffect(() => {
    const handleSubmit = async () => {
      const applicationData = getApplicationData(
        currentUser,
        paymentInstrument
      );

      if (applicationData) {
        try {
          const response = await anywhereCardApplications({
            ...applicationData,
            ...(deviceToken && {
              device_token: deviceToken,
            }),
          });

          trackEvent("Catch Card Application Submitted");

          if (response.status === "failed") {
            setHasError(true);
          } else {
            setIsPolling(true);
          }
        } catch (err) {
          trackError("CatchCardApplication", "Application", { error: err });
          setHasError(true);
        }
      }
    };

    if (
      currentUser &&
      paymentInstrument &&
      catchCard?.user_flow_status === "not_initiated"
    ) {
      void handleSubmit();
    }
  }, [
    currentUser,
    deviceToken,
    paymentInstrument,
    catchCard,
    anywhereCardApplications,
    trackEvent,
    trackError,
  ]);

  useEffect(() => {
    const handleRequest = async () => {
      let response = await refetchCatchCard();

      while (
        response?.user_flow_status !== "approved" &&
        response?.user_flow_status !== "denied" &&
        response?.user_flow_status !== "pending_manual_review"
      ) {
        /* eslint-disable no-await-in-loop */
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await refetchCatchCard();
        /* eslint-enable no-await-in-loop */
      }

      dispatch(setCatchCard(response));
      setIsPolling(false);

      if (response?.user_flow_status === "approved") {
        setSuccess(true);
      }
    };

    if (isPolling) {
      void handleRequest();
    }
  }, [isPolling, dispatch, refetchCatchCard]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (isPolling) {
      timeout = setTimeout(() => {
        setHasTimedOut(true);
      }, 90000);
    }

    return () => {
      timeout && clearInterval(timeout);
    };
  }, [isPolling]);

  useEffect(() => {
    if (success) {
      onSuccess();
    }
  }, [success, onSuccess]);

  useEffect(() => {
    if (hasTimedOut) {
      setErrorType("timeout");
      onError && onError();
    }

    if (catchCard?.user_flow_status === "pending_manual_review") {
      trackEvent("Catch Card Application Pending Manual Review");
      setErrorType("pending");
      onError && onError();
    }

    if (catchCard?.user_flow_status === "denied") {
      trackEvent("Catch Card Application Denied");
      setErrorType("denied");
      onError && onError();
    }

    if (hasError) {
      trackEvent("Catch Card Application Error");
      setErrorType("error");
      onError && onError();
    }
  }, [hasError, hasTimedOut, catchCard, onError, trackEvent]);

  if (errorType) {
    return (
      <SmallPagePanel bgcolor="tertiary.main">
        <Box mt={-4}>
          <CatchCardError
            errorType={errorType}
            showIcon
            mode="dark"
            showContinue={
              errorType === "timeout" || errorType === "pending"
                ? showContinue
                : undefined
            }
          />
        </Box>
      </SmallPagePanel>
    );
  }

  return (
    <SmallPagePanel
      title={
        <>
          Your debit card
          <br /> is getting a glow up.
        </>
      }
      subtitle={
        <>
          Our system is generating your card
          <br />
          details now. It&apos;ll be ready soon!
        </>
      }
      bgcolor="tertiary.main"
      centered
    >
      <Box
        width="100%"
        maxWidth={276}
        py={6}
        sx={{
          ".MuiPaper-rounded": {
            border: `1px solid ${palette.tints?.purple?.main || ""}`,
          },
        }}
      >
        <FlippableCard
          flipped
          front={
            <CatchCard
              sx={{
                borderRadius: 0,
              }}
            />
          }
          back={
            <CatchCardPlaceholders
              sx={{
                borderRadius: 0,
              }}
            />
          }
          elevation={0}
          infinite
        />
      </Box>

      <Typography
        variant="h3"
        position="relative"
        color="tertiary.contrastText"
        minWidth={276}
        minHeight={48}
      >
        <RotatingText messages={APPLICATION_PROCESSING_MESSAGES} />
      </Typography>
    </SmallPagePanel>
  );
};

export default CatchCardApplication;
