import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import CatchCard from "~common/components/cards/CatchCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";
import CatchCardDisclosures from "~src/components/shared/onboarding/CatchCardDisclosures";

type EducationProps = {
  onClick: () => void;
  initialScreen?: string;
};

const Education: React.VFC<EducationProps> = ({ onClick, initialScreen }) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("Catch Card Activate My Card Selected");
    onClick();
  };

  return (
    <Box bgcolor="tertiary.main" px={6} pt={12} pb={8}>
      <Stack
        spacing={12}
        maxWidth={552}
        mx="auto"
        alignItems="center"
        textAlign="center"
      >
        <Stack
          spacing={{
            zero: 12,
            md: 9,
          }}
          px={{
            zero: 6,
            md: 0,
          }}
          alignItems="center"
        >
          <Box
            py={{
              zero: 4.5,
              md: 5.5,
            }}
          >
            <CatchCard
              sx={({ palette }) => ({
                width: {
                  zero: 195,
                  md: 235,
                },
                transform: "rotate(-12deg)",
                border: `1px solid ${palette.tints?.purple?.main || ""}`,
                boxShadow: {
                  zero: `3px 3px 0px 0px ${palette.tints?.purple?.main || ""}`,
                  md: `4px 4px 0px 0px ${palette.tints?.purple?.main || ""}`,
                },
              })}
            />
          </Box>

          <Stack spacing={6} alignItems="center">
            <Typography
              variant="h1"
              color="common.white"
              fontWeight={900}
              letterSpacing="-0.02em"
              sx={{
                fontSize: {
                  md: 36,
                },
              }}
            >
              We&apos;re ready to activate your Catch card!
            </Typography>

            <Typography
              variant="bodyRegular"
              color="tertiary.light"
              maxWidth={360}
            >
              It&apos;s time to{" "}
              <Typography
                variant="inherit"
                component="span"
                color="tertiary.contrastText"
                fontWeight={700}
              >
                glow up your debit card
              </Typography>{" "}
              (have it handy for the next step).
            </Typography>
          </Stack>
        </Stack>

        <PrimaryButton
          onClick={handleClick}
          fullWidth
          sx={{
            maxWidth: 360,
          }}
        >
          Continue
          <Box sx={visuallyHidden}>
            {initialScreen === "pin"
              ? " to create your PIN"
              : " to link your debit card"}
          </Box>
        </PrimaryButton>

        <CatchCardDisclosures />
      </Stack>
    </Box>
  );
};

export default Education;
