import React from "react";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import { useGetExplorePublicMerchantCategories } from "~common/services";

import ExploreCategoriesSkeleton from "./ExploreCategoriesSkeleton";
import ExploreCategory from "./ExploreCategory";

type ExploreCategoriesProps = {
  utmCampaign: string;
};

const ExploreCategories: React.VFC<ExploreCategoriesProps> = ({
  utmCampaign,
}) => {
  const { data, error, loading } = useGetExplorePublicMerchantCategories({
    queryParams: {
      get_affiliate_category: utmCampaign === "web_app",
    },
  });

  return (
    <LoadingGuard {...{ error }}>
      {loading ? (
        <ExploreCategoriesSkeleton />
      ) : (
        data?.categories.map((category) => (
          <ExploreCategory
            key={category.id}
            category={category}
            utmCampaign={utmCampaign}
          />
        ))
      )}
    </LoadingGuard>
  );
};

export default ExploreCategories;
