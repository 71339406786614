import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Stack } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import confetti from "~common/assets/illustrations/confetti.png";
import CatchCardPlaceholders from "~common/components/cards/CatchCardPlaceholders";
import { PrimaryButton } from "~common/components/controls/buttons";
import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { IS_DEV } from "~src/config";
import { selectCatchCard } from "~src/store";

type CatchCardApplicationSuccessProps = {
  onClick: () => void;
};

const CatchCardApplicationSuccess: React.VFC<CatchCardApplicationSuccessProps> =
  ({ onClick }) => {
    const { trackEvent, trackError } = useTracking();
    const catchCard = useSelector(selectCatchCard);
    const [error, setError] = useState(false);

    useEffect(() => {
      /*
       * This event name deviates from our usual pattern because
       * marketing is listening for it in Iterable.
       */
      trackEvent("CatchPass Application Succeeded");
    }, [trackEvent]);

    const handleError = () => {
      setError(true);
      trackError("CatchCardSuccess", "Marqeta bootstrap error");
    };

    const handleCopy = (name: string) => {
      trackEvent(`Catch Card Success Copy ${name} Clicked`);
    };

    const handleClick = () => {
      trackEvent(`Catch Card Success Continue Clicked`);
      onClick();
    };

    return (
      <SmallPagePanel
        title="Yaaaaaaas!"
        subtitle={
          <>
            Your brand new Catch card is all set and ready for your every
            purchase. Simply <strong>make it your default card</strong> in Apple
            Wallet!
          </>
        }
        bgcolor="tints.purple.dark"
        centered
        sx={{
          pb: {
            zero: 18,
            md: 12,
          },
        }}
      >
        <Box
          pt={6}
          width="100%"
          position="relative"
          sx={{
            "&:after": {
              content: "''",
              width: 384,
              height: 375,
              background: `url(${confetti}) no-repeat`,
              backgroundSize: "contain",
              position: "absolute",
              bottom: {
                zero: -28,
                md: 0,
              },
              left: "50%",
              transform: "translateX(-50%)",
            },
          }}
        >
          <Stack
            spacing={12}
            alignItems="center"
            position="relative"
            zIndex={1}
          >
            <Box
              width="100%"
              maxWidth={327}
              overflow="hidden"
              position="relative"
              zIndex={1}
              sx={({ shape }) => ({
                borderRadius: `${shape.borderRadius * 5}px`,
              })}
            >
              {error || !catchCard?.marqeta_token ? (
                <CatchCardPlaceholders />
              ) : (
                <MarqetaCard
                  token={catchCard?.marqeta_token || ""}
                  isSandbox={IS_DEV}
                  onFailure={handleError}
                  onCopy={handleCopy}
                />
              )}
            </Box>

            <PrimaryButton onClick={handleClick} fullWidth>
              Continue<Box sx={visuallyHidden}> to create your PIN</Box>
            </PrimaryButton>
          </Stack>
        </Box>
      </SmallPagePanel>
    );
  };

export default CatchCardApplicationSuccess;
