import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack } from "@mui/material";

import chaChing from "~common/assets/illustrations/cha_ching.png";
import RewardCard from "~common/components/cards/RewardCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import { useClaimRewardCampaign } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking } from "~common/tracking";
import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import { formatMoney } from "~common/utils/money";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectClaims } from "~src/store";
import { setNewUserOnboardingRewardCampaign } from "~src/store/slices/user-slice";
import {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
  REWARD_CAMPAIGN_ERRORS,
} from "~src/utils/reward-campaigns";

import Error from "../../../components/shared/Error";

type ClaimRewardErrors =
  | "creditOnlyForNewUsers"
  | "creditCanOnlyBeClaimedOnce"
  | "creditNoLongerAvailable"
  | null;

type ClaimRewardProps = {
  onSuccess: () => void;
};

const ClaimReward: React.VFC<ClaimRewardProps> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const { mutate: claimRewardCampaign } = useClaimRewardCampaign();
  const [errorType, setErrorType] = useState<ClaimRewardErrors>(null);
  const isFlex =
    newUserOnboardingRewardCampaign?.merchant_id === "FLEX_MERCHANT_ID";

  useEffect(() => {
    const handleSubmit = async () => {
      if (newUserOnboardingRewardCampaign) {
        const rewardCampaignId = getRewardCampaignId(
          newUserOnboardingRewardCampaign.reward_campaign_id
        );

        removeSessionStorageWithFallback(
          NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID
        );

        try {
          await claimRewardCampaign(
            {
              reward_campaign_claim_source: "user_portal_sign_in_page",
            },
            {
              pathParams: {
                rewardCampaignId,
              },
            }
          );

          dispatch(
            setNewUserOnboardingRewardCampaign({
              ...newUserOnboardingRewardCampaign,
              claimed: true,
            })
          );

          trackEvent("Catch Card Onboarding Credit Earned", {
            rewardCampaignId,
          });
        } catch (err) {
          const error = convertRawServiceError(err);

          switch (error.error_type) {
            case "REWARD_CAMPAIGN_NEW_USERS_ONLY":
              setErrorType("creditOnlyForNewUsers");
              break;
            case "REWARD_CAMPAIGN_USER_ALREADY_CLAIMED":
              setErrorType("creditCanOnlyBeClaimedOnce");
              break;
            default:
              setErrorType("creditNoLongerAvailable");
          }

          trackError("ClaimReward", "Failure claiming reward", {
            error,
          });
        }
      }
    };

    if (!newUserOnboardingRewardCampaign?.claimed) {
      void handleSubmit();
    }
  }, [
    newUserOnboardingRewardCampaign,
    dispatch,
    claimRewardCampaign,
    trackEvent,
    trackError,
  ]);

  useEffect(() => {
    if (!newUserOnboardingRewardCampaign) {
      onSuccess();
    }
  }, [newUserOnboardingRewardCampaign, onSuccess]);

  const handleClick = (isError?: boolean) => {
    trackEvent(
      `Onboarding Claim Reward ${isError ? "Error" : ""} Continue Clicked`
    );

    dispatch(setNewUserOnboardingRewardCampaign(undefined));
    onSuccess();
  };

  if (!newUserOnboardingRewardCampaign) {
    return null;
  }

  if (errorType) {
    return (
      <SmallPagePanel>
        <Error
          title={REWARD_CAMPAIGN_ERRORS[errorType]?.title}
          subtitle={REWARD_CAMPAIGN_ERRORS[errorType]?.subtitle}
          onClick={() => handleClick(true)}
        />
      </SmallPagePanel>
    );
  }

  return (
    <SmallPagePanel
      icon={
        <Stack spacing={12} width="100%" alignItems="center" pb={8}>
          <Box
            component="img"
            src={chaChing}
            width={326}
            maxWidth="100%"
            alt="Cha-Ching!"
            sx={{
              aspectRatio: "326 / 42",
            }}
          />
          <RewardCard
            reward={newUserOnboardingRewardCampaign}
            sx={{ width: 280, mb: 8 }}
          />
        </Stack>
      }
      title={`You earned ${formatMoney(
        newUserOnboardingRewardCampaign.total_amount || 0
      )} ${
        isFlex
          ? "in Catch Cash"
          : `credit to ${newUserOnboardingRewardCampaign.merchant_name || ""}`
      }!`}
      subtitle={
        isFlex
          ? "Treat yourself to a little something nice, or keep using your Catch card to rack up even more savings."
          : `Welcome to Catch, where you'll never pay full price again at ${
              newUserOnboardingRewardCampaign.merchant_name || ""
            } and more of the hottest brands.`
      }
      bgcolor={isFlex ? "tints.purple.dark" : "tertiary.main"}
      centered
      sx={{
        py: {
          zero: 16,
          sm: 18,
        },
      }}
    >
      <Box pt={6} width="100%">
        <PrimaryButton onClick={() => handleClick()} fullWidth>
          Shop with Catch
        </PrimaryButton>
      </Box>
    </SmallPagePanel>
  );
};

export default ClaimReward;
