import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.9457 16.4728L9.26226 58.4987C6.8496 62.6551 9.81903 67.8275 14.6444 67.8275H64.104C68.9293 67.8275 71.8988 62.6551 69.4861 58.4987L44.7099 16.4728C42.2972 12.4088 36.3583 12.4088 33.9457 16.4728Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3274 19.9824L16.8711 61.2694H61.8766L39.3274 19.9824Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3276 55.7273C40.1476 55.7273 40.8124 55.0657 40.8124 54.2494C40.8124 53.4332 40.1476 52.7715 39.3276 52.7715C38.5075 52.7715 37.8428 53.4332 37.8428 54.2494C37.8428 55.0657 38.5075 55.7273 39.3276 55.7273Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.4327 44.2804C34.1231 44.2804 34.6828 43.7208 34.6828 43.0304C34.6828 42.34 34.1231 41.7803 33.4327 41.7803C32.7423 41.7803 32.1826 42.34 32.1826 43.0304C32.1826 43.7208 32.7423 44.2804 33.4327 44.2804Z"
      fill="currentColor"
    />
    <path
      d="M45.6817 44.2804C46.3721 44.2804 46.9318 43.7208 46.9318 43.0304C46.9318 42.34 46.3721 41.7803 45.6817 41.7803C44.9913 41.7803 44.4316 42.34 44.4316 43.0304C44.4316 43.7208 44.9913 44.2804 45.6817 44.2804Z"
      fill="currentColor"
    />
    <path
      d="M39.3281 31.9897V48.523"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
