import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { useMerchantbadgeUrl } from "~common/hooks/asset-hooks";
import { useGetRewardCampaign } from "~common/services";
import { useTracking } from "~common/tracking";
import { embeddedMessenger } from "~common/utils/iframe-messaging";
import { WEBFLOW_URL } from "~src/config";
import {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
} from "~src/utils/reward-campaigns";

const RewardBanner: React.VFC = () => {
  const [searchParams] = useSearchParams();
  const rewardCampaignId = searchParams.get(
    NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID
  );
  const { trackEvent } = useTracking();
  const ref = useRef<HTMLElement | null>(null);

  const { data: reward } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId: getRewardCampaignId(rewardCampaignId),
    },
    lazy: !rewardCampaignId,
  });

  const badgeUrl = useMerchantbadgeUrl(reward?.merchant_id);

  useEffect(() => {
    if (reward && ref.current) {
      embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_BANNER_LOAD", {
        height: ref.current.clientHeight,
      });
    }
  }, [reward]);

  const handleClick = () => {
    trackEvent("");
    embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_BANNER_CLICK");
  };

  if (!reward) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={({ palette }) => ({
        background: palette.tints?.purple?.dark,
        p: 4.5,
        px: 4,
        pl: {
          zero: 5,
          sm: 4,
        },
        color: palette.common.white,
      })}
    >
      <Stack
        width="100%"
        spacing={4}
        direction="row"
        alignItems="center"
        sx={{
          justifyContent: {
            zero: "space-between",
            sm: "center",
          },
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          {reward?.merchant_id !== "FLEX_MERCHANT_ID" && (
            <Box
              component="img"
              alt={`${reward.merchant_name || ""} logo`}
              src={badgeUrl}
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
              }}
            />
          )}

          <Typography variant="h3">
            <Typography
              variant="inherit"
              component="span"
              color="tertiary.contrastText"
            >
              {reward.merchant_id === "FLEX_MERCHANT_ID"
                ? "Earn up to $30"
                : "You're earning up to $30"}
            </Typography>

            {reward.merchant_id === "FLEX_MERCHANT_ID"
              ? " in welcome rewards!"
              : ` to ${reward.merchant_name || ""}!`}
          </Typography>
        </Stack>

        <DefaultButton
          onClick={handleClick}
          size="small"
          sx={({ palette }) => ({
            background: palette.common.white,
          })}
        >
          Claim
        </DefaultButton>
      </Stack>
    </Box>
  );
};

export default RewardBanner;
