import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";

import { Box, Stack, StackProps, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { AlertIcon, MeditatingCoinIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";
import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import { setAuthUserInfo } from "~src/store/slices/user-slice";
import {
  APPLICATION_ERROR_MESSAGES,
  ApplicationError,
} from "~src/utils/catch-card";

type CatchCardErrorProps = StackProps & {
  showIcon?: boolean;
  errorType?: ApplicationError;
  onRetryDebit?: () => void;
  showContinue?: boolean;
  mode?: "dark" | "light";
};

const CatchCardError: React.VFC<CatchCardErrorProps> = ({
  showIcon,
  errorType = "error",
  onRetryDebit,
  showContinue,
  mode,
  ...props
}) => {
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const errorMessage = APPLICATION_ERROR_MESSAGES[errorType];

  const handleClick = () => {
    trackEvent("Catch Card Error Contact Clicked");
    intercom.show();
  };

  const handleExploreClick = () => {
    trackEvent("Catch Card Error Explore Catch Clicked");
    removeSessionStorageWithFallback("activateCardRequired");
    dispatch(setAuthUserInfo({ activateCardRequired: false }));
    navigate("/home");
  };

  return (
    <Stack
      spacing={6}
      color={mode === "dark" ? "common.white" : "grey.600"}
      {...props}
    >
      <Stack spacing={2} alignItems="center" textAlign="center">
        {showIcon && !(errorType === "pending" || errorType === "timeout") && (
          <AlertIcon size="small" />
        )}

        {showIcon && (errorType === "pending" || errorType === "timeout") && (
          <MeditatingCoinIcon size="small" />
        )}

        <Typography variant="h2" component="h3">
          {errorMessage.heading}
        </Typography>
      </Stack>

      <Stack
        spacing={6}
        textAlign={errorMessage.suggestions ? "left" : "center"}
      >
        {errorMessage.body.map((paragraph) => (
          <Typography key={paragraph} variant="bodyRegular">
            {paragraph}
          </Typography>
        ))}

        {errorMessage.suggestions && (
          <Box component="ul" pl={4}>
            {errorMessage.suggestions.map((suggestion) => (
              <Box key={suggestion} component="li">
                <Typography variant="bodyRegular">{suggestion}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Stack>

      {errorType === "error" && (
        <DefaultButton
          onClick={handleClick}
          sx={({ palette }) => ({
            ...(mode === "dark" && {
              background: palette.common.white,
              color: palette.grey[700],
            }),
          })}
        >
          Contact us
        </DefaultButton>
      )}

      {errorType === "debitAuthFailed" && onRetryDebit && (
        <DefaultButton
          onClick={onRetryDebit}
          sx={({ palette }) => ({
            ...(mode === "dark" && {
              background: palette.common.white,
              color: palette.grey[700],
            }),
          })}
        >
          Link a new debit card
        </DefaultButton>
      )}

      {(errorType === "pending" || errorType === "timeout") && showContinue && (
        <DefaultButton
          onClick={handleExploreClick}
          sx={({ palette }) => ({
            ...(mode === "dark" && {
              background: palette.common.white,
              color: palette.grey[700],
            }),
          })}
        >
          Explore Catch
        </DefaultButton>
      )}
    </Stack>
  );
};

export default CatchCardError;
